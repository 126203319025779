/* Portal configurations - global scope */

const helper = {};

// base config
helper.portal_name = 'SoundeesConcerts';
helper.portal_url = "soundees-concerts.com";

// api methods
helper.api_url = 'https://api.hubdelivery.io';
helper.api_methods = {
    'concerts': {
        'categories' : '/soundees/concerts/categories',
        'concerts_by_category' : '/soundees/concerts/concerts_by_category',
        'concert' : '/soundees/concerts/concert',
    },
    'legals': {
        'contact' : '/soundees/legals/contact',
        'faq' : '/soundees/legals/faq',
        'terms' : '/soundees/legals/terms',
        'privacy' : '/soundees/legals/privacy',
        'cookies_policy' : '/soundees/legals/cookies_policy',
        'unsubscribe_text' : '/soundees/legals/unsubscribe',
        'unsubscribe_phone' : '/store/UnsubscribePhoneNumber'
    },
    'general': {
        'login' : '/store/checkAccessCode'
    }
};

helper.country_phone_format = {
    'UK': '44xxxxxxxxx',
    'DE': '42xxxxxxxxx',
    'BG': '08xxxxxxxx',
    'RS': '06xxxxxxxx'
};

helper.country_default_language = {
    'UK': 'EN'
};

helper.country_register_landing = {
    'UK': 'https://uk.soundees-concerts.com/register',
    'BG': 'https://premium.soundees-concerts.com/bgSc77/',
    'DE': 'https://premium.soundees-concerts.com/deSc40/clk/',
    'RS': 'https://premium.soundees-concerts.com/rSsC09/'
};

export const helpers = helper;